
import { Options } from "vue-class-component";
import NodesConfigMixins from "../NodesConfigMixins";

@Options({
    name: "flowable-nodes-concurrent-node-config",
})
export default class ConcurrentNodeConfig extends NodesConfigMixins {
  //节点配置
}
